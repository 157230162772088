import React from "react";

export const Insurance = (props) => {
  return (
    <div id="insurance" className="text-center row">
      <div className="container insurance">
        <div className="col-md-offset-6 col-md-6">
          <h2>Seguros</h2>
        </div>
      </div>
    </div>
  );
};
