import React from "react";

export const Offer = (props) => {
  return (
    <div id="offer" className="text-center row">
      <div className="container">
      <div className="section-title">
          <h2>¿Qué ofrecemos?</h2>
          <p>
          Un análisis detallado de las necesidades específicas del cliente, brindando propuestas alternativas que equilibran servicio, calidad y costo. Nuestro equipo, altamente capacitado, proporciona atención personalizada y cotizaciones ágiles para cualquier tipo de seguro. Contamos con coberturas y programas tanto a nivel nacional como internacional, respaldados por las compañías más sólidas del mercado asegurador.
          </p>
        </div>
      </div>
    </div>
  );
};
