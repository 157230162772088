import React from "react";

export const Bail = (props) => {
  return (
    <div id="bail" className="text-center row">
      <div className="container bail">
        <div className="col-md-offset-6 col-md-6">
          <h2>Fianzas</h2>
        </div>
      </div>
    </div>
  );
};
