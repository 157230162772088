import React from "react";

export const Confidence = (props) => {
  return (
    <div id="confidence" className="text-center row">
      <div className="container confidence">
        <div className="section-title">
          <h2>Confianza a tu lado siempre</h2>
        </div>
      </div>
    </div>
  );
};
